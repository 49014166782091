<template>
	<div class="success-status">
		<slot name="statusImg">
			<img src="~@/assets/img/ticket/success.png" alt="">
		</slot>
		<div class="status-describe">
			<slot name="statusDescribe">企业准入提交成功</slot>
		</div>
		<div class="status-btn">
			<slot name="statusBtn"></slot>
		</div>
	</div>
</template>

<script>
  export default {
    name: "Status"
  }
</script>

<style lang="less" scoped>
	.success-status{
		background: #fff;
		min-height: 500px;
		/*box-shadow: 0 1px 1px rgba(0,0,0,0.15) !important;*/
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.status-describe{
			font-size: 18px;
			margin-top: 30px;
			text-align: center;
		}
		.el-button{
			width: 160px;
			margin-top: 60px;
		}
	}
</style>