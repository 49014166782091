<template>
  <div>
    <!-- 票据基本信息 -- 用的是工作台公共的子组件-里面包括票面预览和票面下载 -->
    <el-card>
      <WorkbenchPublicTitle :superInfoList="superInfoList">
        <template v-slot:titleText>
          <h3>票据基本信息</h3>
        </template>
      </WorkbenchPublicTitle>
      <el-table :data="superInfoList" border class="mt20">
        <el-table-column prop="packageNo" label="票据包号"></el-table-column>
        <el-table-column prop="packageSum" label="票据包金额"></el-table-column>
        <el-table-column prop="acceptor" label="承兑人"></el-table-column>
        <el-table-column prop="expireDate" label="票据到期日"></el-table-column>
        <el-table-column prop="ticketRange" label="子票区间"></el-table-column>
      </el-table>
    </el-card>
    <!-- 收款信息 -->
    <el-card>
      <el-descriptions title="收款信息" :column="2" class="mt30">
        <el-descriptions-item label="收款人">{{payeeInfo.compName}}</el-descriptions-item>
        <el-descriptions-item label="收款账号">{{payeeInfo.acctNo}}</el-descriptions-item>
        <el-descriptions-item label="开户行">{{payeeInfo.acctBankName}}</el-descriptions-item>
        <el-descriptions-item label="开户行行号">{{payeeInfo.acctBankNo}}</el-descriptions-item>
        <el-descriptions-item label="清算方式">{{payeeInfo.clrWay}}</el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 原提示付款申请操作编号 -->
    <el-card>
      <el-descriptions title="原提示付款申请操作编号" :column="2" class="mt30">
        <el-descriptions-item label="操作编号">
          <span class="operation-number">{{fksOperNo}}</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
    <!-- 按钮 -->
    <div class="btn">
			<el-button @click="handleBack">返回</el-button>
			<el-button type="primary" @click="messageDialogVisible = true">确认提交</el-button>
		</div>
    <!--短信验证-->
    <el-dialog
            title="短信验证"
            :visible.sync="messageDialogVisible"
            :close-on-click-modal="false"
            width="40%"
            @close="messageDialogClosed">
      <el-form :model="messageForm"
               :rules="messageFormRules"
               ref="messageFormRef"
               class="message-form">
        <el-form-item label="操作员手机号">
          {{mobile}}
        </el-form-item>
        <el-form-item label="短信验证码" prop="verifyCode">
          <el-input v-model="messageForm.verifyCode" class="verify-code"></el-input>
          <DelayButton />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleThroughSubmit">提交</el-button>
		    <el-button @click="messageDialogVisible = false">关闭</el-button>
		  </span>
    </el-dialog>
  </div>
</template>

<script>
  // 票面预览/票面下载
  import WorkbenchPublicTitle from "@/views/Ticket/routerCpn/workbench/publicCpn/WorkbenchPublicTitle";
  // 倒计时按钮
  import DelayButton from "@/views/Ticket/childrenCpn/DelayButton";
  export default {
    name: 'PaymentRecallOne',
    components: {
      WorkbenchPublicTitle,
      DelayButton
    },
    mounted(){
      // 列表返回的票据id
      this.ticketBizId = this.$route.query.ticketBizId
      // 获取提示付款申请信息
      this.getInfo()
    },
    data(){
      return {
        ticketBizId: '', // 列表页返回的业务记录id
        handlerTypeCode: '', // 操作类型code	
        handlerNodeCode: '', // 节点状态code	
        superInfoList: [], // 公共组件 - 票面预览/下载
        payeeInfo: {// 收款信息	
          compName: '', // 收款人
          acctNo: '', // 收款账号	
          acctBankName: '', // 开户行	
          acctBankNo: '', // 开户行行号	
          clrWay: '', // 清算方式	
        },
        fksOperNo: '', //原提示付款申请操作编号
        mobile: this.$store.state.getUser, // 获取vuex中存的手机号
        identifyCode:'', // 短信标识码
        messageDialogVisible: false, // 控制短信弹框的显示与隐藏
        // 短信验证表单数据
        messageForm: {
          verifyCode: ''
        },
        // 短信验证表单验证规则
        messageFormRules:{
          verifyCode: [
            { required: true, message: '请输入验证码', trigger: 'blur' }
          ]
        },
      }
    },
    methods: {
      // 获取提示付款申请信息
      async getInfo(){
        const {data: res} = await this.$auth.get(`/cpiaoju-ticket/fk/mange/cancel/info/${this.ticketBizId}`)
        console.log(res);
        if(res.code !== 200) return this.$message.error(res.msg)
        this.superInfoList = res.data.baseInfos
        this.payeeInfo = res.data.payeeInfo
        this.fksOperNo = res.data.fksOperNo
        this.handlerTypeCode = res.data.handlerTypeCode
        this.handlerNodeCode = res.data.handlerNodeCode
      },
      // 返回
      handleBack(){
        this.$router.push({name: 'paymentRecallList'})
      },
      // 短信确定提交
      handleThroughSubmit(){
        this.$refs.messageFormRef.validate(async valid => {
          let verifyParams = {
            ticketId: this.ticketBizId,
            handlerTypeCode: this.handlerTypeCode,
            handlerNodeCode: this.handlerNodeCode,
            smsCode: this.messageForm.verifyCode
          };
          if(!valid) return;
          // 短信验证
          const {data: res} = await this.$auth.post('/cpiaoju-ticket/workbench/task/todo/verifyCode',this.$qs.stringify(verifyParams))
          if(res.code === 200){
            // 提示付款撤销-确认提交
            const {data: result} = await this.$auth.post('/cpiaoju-ticket/fk/mange/cancel/submit', this.$qs.stringify({ticketBizId: this.ticketBizId}))
            if(result.code !== 200) return this.$message.error(result.msg)
            this.messageDialogVisible = false
            this.$emit('handleSubmit', {activeStep: 2, text: result.data.text, handlerNo: result.data.handlerNo})
          }else{
            this.$message.error(res.msg)
            this.messageDialogVisible = true
          }
        })
      },
      // 审核通过短信验证关闭
      messageDialogClosed(){
        this.$refs.messageFormRef.resetFields()
      },
    }
  }
</script>

<style lang="less" scoped>
  /* /deep/ .el-descriptions__body{
    padding-left: 20px;
  } */
  // 按钮
  .btn{
    text-align: center;
    margin: 50px auto 20px;
    .el-button{
      min-width: 120px;
      margin: 0 30px;
    }
  }
  .verify-code{
		width: 220px;
	}
  // 短信验证框
  .message-form{
    .el-form-item__error{
      left: 90px !important;
    }
  }
  // 操作编号
  .operation-number{
    color: #C70009;
    font-weight: bold;
  }
</style>